body {
  background-color: #222222;
  font-family: 'Roboto'
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Header {
  height: 10vh;
  width: 100vw;
  background-color: #F2F2F2;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Header-Options {
  width: 22vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.Map-Container {
  margin-top: 17.5vh;
  width: 87.5vw;
  height: 75vh;
  background-color: #dfe6e9;
  border: 4px solid white;
}

.Map-Modal {
  position: absolute;
  top: 20%;
  left: 7.5%;
  min-height: 480px;
  width: 380px;
  background-color: #2d3436;
  z-index: 800;
  box-shadow: 7px 7px 8px -3px #888888;
}


.route-modal {
  min-height: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link-text {
  text-decoration: none;
  color: #2d3436;
}

.link-text:hover {
  cursor: pointer;
}

.Modal-Container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(45, 52, 54,0.9);
  z-index: 899;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Welcome {
  background-color: #F2F2F2;
  margin-top: 20vh;
  height: 60vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Directions-Component {
  margin-top: 7.5vh;
  width: 87.5vw;
  background-color: #dfe6e9;
  border: 4px solid white;
}

.slide-out {
  animation: slide-out .5s ease-in forwards;
}

@keyframes slide-out {
  from {
    transform: translateY(0%);
    opacity: 1.0;
  }
  to {
    transform: translateY(-1000px);
    opacity: 0.0;
  }
}

.slide-in {
  animation: slide-in .5s ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(1000px);
    opacity: 0.0;
  }
  to {
    transform: translateY(0%);
    opacity: 1.0;
  }
}

.slide-down {
  animation: slide-down .5s ease-in forwards;
}

@keyframes slide-down {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-130%);
  }
}

.slide-down-snack {
  animation: slide-down-snack 0.75s ease-in forwards
}

@keyframes slide-down-snack {
  from {
    transform: translateY(-100px);
    opacity: 0;
    z-index: 100;
  }
  to {
    transform: translateY(0%);
    opacity: 1.0;
    z-index: 999;
  }
}

.slide-up {
  animation: slide-up .75s ease-in forwards;
}

@keyframes slide-up {
  from {
    transform: translateX(-130%);
  }
  to {
    transform: translateX(0%);
  }
}

.invisible {
  opacity: 0;
}

.title {
  height: 20%;
  width: 100%;
  background-color: #d63031;
  display: flex;
  align-items: center;
  box-shadow: 0px 7px 8px -3px #888888;
  z-index: 998;
}

.two-button-container {
  width: 96%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  height: 55px;
  width: 150px;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 7px 8px -3px #888888;
}

.red {
  background-color: #d63031;
}

.button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.hover:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 5px 5px 10px -3px #888888;
}

.hover-small {
  color: #b2bec3;
}

.hover-small:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #2d3436;
}

.hover-marker:hover {
  cursor: grab;
  transform: scale(1.1);
}

.hover-marker:active {
  cursor: grabbing;
  transform: scale(1.1);
}

.register {
  height: 80vh;
  margin-top: 15vh;
}

.register-title {
  height: 12%;
}

.top-bar {
  height: 50px;
  background-color: #d63031;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}

.route {
  justify-content: space-between;
}

.pop-out {
  animation: pop-out .9s ease-in forwards;
}

@keyframes pop-out {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(1.5);
  }
}

.error-message-modal {
  position: absolute;
  margin-top: 12vh;
  width: 250px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.success {
  background-color: #00b894;
}

.failure {
  background-color: #2d3436;
}

.registration {
  margin-top: 9.5vh;
}

.snackbar {
  position: absolute;
  top: 70px;
  left: 35vw;
  width: 30vw;
  height: 90px;
  box-shadow: 5px 5px 10px -3px #888888;
  background-color: #d63031;
  display: flex;
  justify-content: center;
}
